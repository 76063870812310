import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
    mode: "hash",
    routes: [
        { path: "*", redirect: "/index" },
        // 登录页面
        {
            path: "/login",
            name: "login",
            component: () => import("@/views/login.vue"),
        },
        {
            path: "/index",
            name: "index",
            component: () => import("@/views/index.vue"),
        },
        {
            path: "/recharge",
            name: "recharge",
            component: () => import("@/views/recharge.vue"),
        },
        {
            path: "/coupon",
            name: "coupon",
            component: () => import("@/views/coupon.vue"),
        },
        {
            path: "/39.9/index",
            name: "39.9/index",
            component: () => import("@/views/39.9/index.vue"),
        },
        {
            path: "/39.9/purchase",
            name: "39.9/purchase",
            component: () => import("@/views/39.9/purchase.vue"),
        },
        {
            path: "/49.9/purchase",
            name: "49.9/purchase",
            component: () => import("@/views/49.9/purchase.vue"),
        },
        {
            path: "/ninety-nine",
            name: "ninety-nine",
            component: () => import("@/views/99.9/purchase.vue"),
        },
        {
            path: "/pay",
            name: "pay",
            component: () => import("@/views/pay/index.vue"),
        },
        {
            path: "/pay-result",
            name: "payResult",
            component: () => import("@/views/pay-result.vue"),
        },
        {
            path: "/pay/history",
            name: "payHistory",
            component: () => import("@/views/pay-history.vue"),
        },
        {
            path: "/recharge/history",
            name: "rechargeHistory",
            component: () => import("@/views/recharge-history.vue"),
        },
        {
            path: "/zero",
            name: "zero",
            component: () => import("@/views/zero/index.vue"),
        },
        {
            path: "/privacy",
            name: "privacy",
            component: () => import("@/views/zero/privacy.vue"),
        },
        {
            path: "/hundred",
            name: "100/index",
            component: () => import("@/views/100/index.vue"),
        },
        {
            path: "/fifty",
            name: "fifty",
            component: () => import("@/views/50/index.vue"),
        },
        {
            path: "/activity-privacy",
            name: "activity-privacy",
            component: () => import("@/views/activity-privacy.vue"),
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
